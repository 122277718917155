import React, { useState } from "react";
import AddressIcon from "../../assets/icons/address.png";
import PhoneIcon from "../../assets/icons/mobile-notch.png";
import WebsiteIcon from "../../assets/icons/website.png";
import { InputText } from "primereact/inputtext";
import ButtonComponent from "../../components/buttons/button";
import Captcha from "./Captcha";
import api from "../../services/api";

const ContactForm = ({ setThanks, thanks }) => {
  const [isError, setIsError] = useState({});
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
    captcha: "",
  });
  const [token, setToken] = useState(null);

  const handleChange = (name) => (event) => {
    setData({ ...data, [name]: event.target.value });
    setIsError({ ...isError, [name]: "" });
  };

  const Validate = () => {
    let errors = {};
    if (!data.name) {
      errors.name = "Enter name ";
    }
    if (!data.email) {
      errors.email = "Enter email ";
    }
    if (!data.message) {
      errors.message = "Enter message ";
    }
    if (!token) {
      errors.captcha = "Please Verify ";
    }

    setIsError(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const onClickSubmit = async () => {
    let errors = await Validate();
    if (errors) sendEmail();
  };

  const sendEmail = async () => {
    let res = await api(
      "post",
      "https://api.appdeft.biz/api/email/contact",
      data
    );
    if (res.success) {
      setThanks(true);
    }
  };

  return (
    <>
      <div className="form-content-container py-5" id="contact">
        <div className="text-center">
          <h1 className="font-bold text-main  " style={{ fontSize: "22px" }}>
            CONTACT
          </h1>
          <p className="text-base font-normal  text-gray-light">
            Reach us to know more about our Services and White Label Solutions
            <br />
            Add this content for Contact Section
          </p>
        </div>
        <div className="grid">
          <div className="col-12 md:col-10 ml-auto mr-auto">
            <InputText
              type="text"
              placeholder="Name"
              className="w-full "
              onChange={handleChange("name")}
            ></InputText>
            {isError?.name && (
              <p className="text-red-600 text-xs mt-1">{isError.name}</p>
            )}
            <div className="my-5">
              <InputText
                type="Email"
                placeholder="Email"
                className="w-full  "
                onChange={handleChange("email")}
              ></InputText>
              {isError?.email && (
                <p className="text-red-600 text-xs mt-1 ">{isError.email}</p>
              )}
            </div>
            <InputText
              type="textarea"
              placeholder="Message"
              className="w-full"
              onChange={handleChange("message")}
            />
            {isError?.message && (
              <p className="text-red-600 text-xs mt-1">{isError.message}</p>
            )}
          </div>
        </div>
        <div className="captcha  py-5">
          <Captcha
            onTokenChange={setToken}
            isError={isError}
            setIsError={setIsError}
            name={"captcha"}
          ></Captcha>
        </div>
        {thanks ? (
          <p className="text-center">
            Thanks for contacting us. We will get you back shortly
          </p>
        ) : null}
        <div className="submit-button text-center mb-8">
          <ButtonComponent
            onClick={onClickSubmit}
            label="SEND MESSAGE"
            className="bg-main p-3 lg:w-4 md:w-6 w-full"
            disabled={thanks}
          ></ButtonComponent>
        </div>

        <div className="grid flex justify-content-evenly justify-content-center py-5">
          <div className="col-12 md:col-3 text-center">
            <img src={AddressIcon} width={47} height={47} alt="" />
            <h2 className="text-base font-semibold  text-gray">ADDRESS</h2>
            <p className="text-sm font-medium  text-gray-light">
              F-301, 2nd Floor Phase 8B, Industrial Area, Mohali, Punjab 160055
              India
            </p>
          </div>
          <div className="col-6 md:col-3 text-center">
            <img src={PhoneIcon} width={47} height={47} alt="" />
            <h2 className="text-base font-semibold  text-gray">CONTACT</h2>
            <p className="text-sm font-medium  text-gray-light">
              +91-85128 48107
            </p>
          </div>
          <div className="col-6 md:col-3 text-center">
            <img src={WebsiteIcon} width={47} height={47} alt="" />
            <h2 className="text-base font-semibold  text-gray">WEBSITE</h2>
            <p className="text-sm font-medium  text-gray-light">Coming Soon</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
