import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dialog } from "primereact/dialog";
import Logo from "../../assets/logo/appdeft logo.png";
import DownArrowIcon from "../../assets/icons/down arrow.png";
import MenutoggleIcon from "../../assets/icons/hamburgerIcon.png";
import ButtonComponent, {
  ButtonOutlined,
} from "../../components/buttons/button";
import { InputText } from "primereact/inputtext";
import SendIcon from "../../assets/icons/send-fill.png";
import { Link } from "react-router-dom";
import AboutContent from "./AboutContent";
import TwitterIcon from "../../assets/icons/devicon_twitter.png";
import ParticleComponent from "./ParticleComponent";
import ContactForm from "./ContactForm";
import api from "../../services/api";

export default function Home() {
  const [visible, setVisible] = useState(false);
  const [click, setClick] = useState(false);
  const [thanks, setThanks] = useState(false);
  const [data, setData] = useState({ email: "" });
  const [contactVisible, setContactVisible] = useState(false);
  const HandleClick = () => {
    setClick(!click);
  };
  const displayId = (event) => {
    window.location.replace(event);
    console.log(event);
    if (event === "/#contact") {
      setContactVisible(true);
    } else {
      setContactVisible(false);
    }
  };

  useEffect(() => {
    setThanks(false);
  }, [click, visible]);

  const notifyMe = async () => {
    if (data.email !== "") {
      let res = await api(
        "post",
        "https://api.appdeft.biz/api/email/notify",
        data
      );
      if (res.success) {
        setThanks(true);
      }
    }
  };

  return (
    <>
      <div className="home-page-container">
        <div className="grid p-0 m-0">
          <div
            className=" top-container col-12 md:col-6 p-0 m-0"
            style={{ width: !click ? "100%" : "", height: "100vh" }}
          >
            <ParticleComponent />
            <div className="landing-page-content h-full ">
              <div className="titile-content h-full ">
                <div className="menuToggle-content text-right pt-5 mr-5 relative">
                  <div className="flex justify-content-end">
                    <span
                      className="ml-5 col-transform"
                      style={{
                        display: !click ? "block" : "none",
                        transition: !click ? "width 2000s" : "width 2000s",
                      }}
                    >
                      <img
                        src={MenutoggleIcon}
                        height={33}
                        width={33}
                        alt="HamburgerIcon"
                        onClick={HandleClick}
                        className="cursor-pointer toggle-menu-icon block"
                      />
                    </span>
                  </div>
                </div>
                <div
                  className="flex align-items-center justify-content-center"
                  style={{ height: "90%" }}
                >
                  <div className="text-center text-white">
                    <div className="logo mb-3">
                      <img
                        src={Logo}
                        width={88}
                        height={88}
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="title-text">
                      <h1
                        className=" font-normal lg:text-5xl text-2xl launching-title p-0 m-0"
                        style={{ letterSpacing: "8px" }}
                      >
                        WE'RE LAUNCHING OUR{" "}
                      </h1>
                      <h2
                        className=" font-bold text-5xl p-0 m-0"
                        style={{ letterSpacing: "8px" }}
                      >
                        NEW WEBSITE
                      </h2>
                      <div className="text-center flex justify-content-center">
                        <p
                          className={`font-medium text-base mb-5 text-light p-3 lg:p-0   ${
                            !click ? "lg:w-5" : "w-8"
                          }`}
                        >
                          Embark on a journey into the future with APPDEFT.
                          We're gearing up to unveil cutting-edge solutions and
                          unparalleled services. For more information, reach out
                          to us and be part of the innovation wave."
                        </p>
                      </div>
                      <img
                        src={DownArrowIcon}
                        width={17}
                        height={32}
                        alt=""
                        className=""
                      />
                    </div>

                    {/* Button  */}
                    <div className="flex justify-content-center py-6">
                      <div
                        className={`btn-box w-2 ${
                          !click ? "w-5 lg:w-2" : "w-3"
                        }`}
                      >
                        <ButtonComponent
                          label="NOTIFY ME"
                          className=" bg-main text-main-color w-full border-transparent p-3 "
                          onClick={() => setVisible(true)}
                        />
                      </div>

                      <div
                        className={`btn-box w-2 ml-3   ${
                          !click ? "w-5 lg:w-2" : "w-3"
                        }`}
                      >
                        <ButtonOutlined
                          onClick={HandleClick}
                          label="MORE INFO"
                          className="border-1 w-full bg-transparent p-3 more-info"
                        ></ButtonOutlined>
                      </div>
                    </div>

                    {/* Social-media-icons */}
                    <div className="social-media-icons lg:mb-0 mb-3">
                      <span>
                        <Link
                          to="https://www.instagram.com/appdeft"
                          className="no-underline text-white"
                        >
                          <i
                            className="pi pi-instagram instagram cursor-pointer"
                            style={{ fontSize: "1.563rem" }}
                          ></i>
                        </Link>
                      </span>

                      <span className="ml-5">
                        <Link to="" className="no-underline text-white">
                          <i
                            className="pi pi-facebook facebook cursor-pointer"
                            style={{ fontSize: "1.563rem" }}
                          ></i>
                        </Link>
                      </span>

                      <span className="ml-5">
                        <Link
                          to="https://www.linkedin.com/company/app-deft/"
                          className="no-underline text-white"
                        >
                          <i
                            className="pi pi-linkedin linkedin cursor-pointer"
                            style={{ fontSize: "1.563rem" }}
                          ></i>
                        </Link>
                      </span>

                      <span className="ml-5 ">
                        <Link
                          to="https://www.linkedin.com/company/app-deft/"
                          className="no-underline text-white"
                        >
                          <img
                            src={TwitterIcon}
                            width={25}
                            height={25}
                            alt=""
                            className="cursor-pointer TwitterIcon "
                          />
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* DAILOG-NOTIFY-CONTENT */}
            <Dialog
              className="bg-main lg:w-4 md:w-5 w-11"
              visible={visible}
              style={{ width: "100vw" }}
              onHide={() => setVisible(false)}
            >
              <div className="m-0 modal-content py-5">
                <h1
                  className=" text-white  text-center font-light"
                  style={{ fontSize: "30px" }}
                >
                  STAY <span className="  font-semibold">TUNED</span>
                </h1>
                <p className="font-medium text-base mb-5 text-light text-center">
                  We launch our new website soon. Please stay updated and
                  follow.
                </p>
                <div className="flex justify-content-center flex-column align-items-center">
                  <div className="flex align-items-center border-1 border-white lg:w-6">
                    <InputText
                      className="bg-main p-3 w-full border-0"
                      placeholder="ENTER YOUR EMAIL HERE..."
                      name="email"
                      onChange={(e) => setData({ email: e.target.value })}
                    />
                    <span className="mr-3 cursor-pointer">
                      <img
                        src={SendIcon}
                        width={22}
                        height={22}
                        alt=""
                        onClick={notifyMe}
                      />
                    </span>
                  </div>
                  {thanks ? (
                    <p className="text-center">
                      Thanks for your interest. We'll notify you when new
                      website is ready..
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Dialog>
          </div>

          {/* ABOUT-CONTENT */}
          <div
            className={`absolute lg:relative about-container col-12 md:col-6 lg:overflow-y-scroll z-1 ${
              !click ? "" : "mobile-sidebar"
            }`}
            style={{
              backgroundColor: "#FAFAFA",
              display: !click ? "none" : "block",
              height: "100vh",
            }}
          >
            <div className="relative sidebar-inner-content h-full ">
              <div
                className="nav-links-show"
                style={{ display: !click ? "none" : "flex" }}
              >
                <div>
                  <Link
                    to=""
                    onClick={() => displayId("/#about")}
                    className={`text-white cursor-pointer no-underline text-base font-medium  ${
                      contactVisible === false ? "  active  " : "nav-link "
                    }`}
                  >
                    ABOUT
                  </Link>
                  <Link
                    to=""
                    onClick={() => displayId("/#contact")}
                    className={`text-white cursor-pointer no-underline text-base font-medium ml-5 ${
                      contactVisible === true ? "  active  " : "nav-link "
                    }`}
                  >
                    CONTACT
                  </Link>
                </div>
                <span className="ml-5">
                  <i
                    className="pi pi-times text-white cursor-pointer"
                    style={{ fontSize: "1rem" }}
                    onClick={HandleClick}
                  ></i>
                </span>
              </div>
              <AboutContent />
              {/* FORM */}
              <ContactForm thanks={thanks} setThanks={setThanks} />
            </div>
          </div>
        </div>
      </div>

      {/* for mobile screen */}
    </>
  );
}
