import React from "react";
import BorderCard from "../../components/cards/card";
import ContentData from "./Content";

export default function AboutContent() {
  const {
    ServiceContent,
    ExpertiesMobile,
    ExpertiesWeb,
    Ecommerce,
    CMS,
    WhiteLabelSolution,
  } = ContentData();

  return (
    <>
      {/* ABOUT-CONTENT */}
      <div className="about-container-box" id="about">
        <div className="lg:p-5 md:p-3  ">
          <h1
            className="font-bold text-main  heading-text"
            style={{ fontSize: "22px" }}
          >
            ABOUT US
          </h1>
          <p className="text-base font-normal  text-gray-light ">
            At APPDEFT, we're not just engineers; we're artists of code and
            architects of design. Our goal is simple : make our end products
            scream engineering excellence and boast a sleek, progressive design
            aesthetic.
          </p>
          <p className="text-base font-normal  text-gray-light mb-5">
            We're not just here for a project; we're here for a partnership.
            Expect us to exceed your expectations. Our focus extends beyond mere
            functionality; we build self sustainable, revenue-generating systems
            that elevate your idea and captivate your users. Think of us as your
            digital growth partners, dedicated to transforming your vision into
            a digital masterpiece.
          </p>

          {/* SERVICE-CONTENT */}
          <div className="service-content mb-5">
            <h1
              className="font-bold text-main  heading-text"
              style={{ fontSize: "22px" }}
            >
              SERVICES
            </h1>
            <div className="grid">
              {ServiceContent.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 md:col-6 lg:col-4 xl:col-3 "
                  >
                    <BorderCard>
                      <div className="content text-center">
                        <img alt="" src={item.img} width={40} height={40} />
                        <p className="text-sm font-medium text-dark-gray ">
                          {item.servicename}
                        </p>
                      </div>
                    </BorderCard>
                  </div>
                );
              })}
            </div>
          </div>

          {/* OUR MOBILE EXPERTISE CONTENT  */}
          <div className="experties-mobile-content mb-5">
            <h1
              className="font-bold text-main  heading-text"
              style={{ fontSize: "22px" }}
            >
              OUR MOBILE EXPERTISE
            </h1>
            <div className="grid">
              {ExpertiesMobile.map((item, index) => {
                return (
                  <div key={index} className="col-6 md:col-6 lg:col-4 xl:col-3">
                    <BorderCard>
                      <div className="content text-center">
                        <img alt="" src={item.img} />
                        <p className="text-sm font-medium text-dark-gray ">
                          {item.servicename}
                        </p>
                      </div>
                    </BorderCard>
                  </div>
                );
              })}
            </div>
          </div>

          {/* OUR WEB EXPERTISE CONTENT */}
          <div className="experties-web-content mb-5">
            <h1
              className="font-bold text-main  heading-text"
              style={{ fontSize: "22px" }}
            >
              OUR WEB EXPERTISE
            </h1>
            <div className="grid">
              {ExpertiesWeb.map((item, index) => {
                return (
                  <div key={index} className="col-6 md:col-6 lg:col-4 xl:col-3">
                    <BorderCard>
                      <div className="content text-center">
                        <img alt="" src={item.img} />
                        <p className="text-sm font-medium text-dark-gray ">
                          {item.servicename}
                        </p>
                      </div>
                    </BorderCard>
                  </div>
                );
              })}
            </div>
          </div>

          {/* ECOMMERCE CONTENT */}
          <div className="ecommerce-content mb-5">
            <h1
              className="font-bold text-main  heading-text"
              style={{ fontSize: "22px" }}
            >
              ECOMMERCE
            </h1>
            <div className="grid">
              {Ecommerce.map((item, index) => {
                return (
                  <div key={index} className="col-6 md:col-6 lg:col-4 xl:col-3">
                    <BorderCard>
                      <div className="content text-center">
                        <img alt="" src={item.img} />
                        <p className="text-sm font-medium text-dark-gray ">
                          {item.servicename}
                        </p>
                      </div>
                    </BorderCard>
                  </div>
                );
              })}
            </div>
          </div>

          {/* CMS CONTENT */}
          <div className="ecommerce-content mb-5">
            <h1
              className="font-bold text-main  heading-text"
              style={{ fontSize: "22px" }}
            >
              CMS
            </h1>
            <div className="grid">
              {CMS.map((item, index) => {
                return (
                  <div key={index} className="col-6 md:col-6 lg:col-4 xl:col-3">
                    <BorderCard>
                      <div className="content text-center">
                        <img alt="" src={item.img} />
                        <p className="text-sm font-medium text-dark-gray ">
                          {item.servicename}
                        </p>
                      </div>
                    </BorderCard>
                  </div>
                );
              })}
            </div>
          </div>

          {/* WHITE LABLE SOLUTION CONTENT */}
          <div className="ecommerce-content mb-5">
            <h1
              className="font-bold text-main heading-text"
              style={{ fontSize: "22px" }}
            >
              WHITE LABLE SOLUTION
            </h1>
            <div className="grid">
              {WhiteLabelSolution.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-6 md:col-6 lg:col-6 xl:col-6"
                    style={{ maxHeight: "193px" }}
                  >
                    <BorderCard>
                      <div className="content text-center ">
                        <img alt="" src={item.img} />
                        <p className="text-sm font-medium text-dark-gray ">
                          {item.servicename}
                        </p>
                      </div>
                    </BorderCard>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
