
import React from 'react'
import { Card } from 'primereact/card';
export default function BorderCard({ children, header }) {
    return (
        <>
            <Card header={header} className="card shadow-none border-1 h-full bg-green-light border-round-lg cursor-pointer" >
                {children}
            </Card>
        </>
    )
}