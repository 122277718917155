import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { constants } from "../../utils/constants";

const Captcha = ({ onTokenChange, isError, setIsError, name }) => {
  const captchaRef = useRef(null);

  const onChange = () => {
    const token = captchaRef.current.getValue();
    onTokenChange(token);
    if (token) {
      setIsError({ ...isError, [name]: "" });
    }
    console.log("onchange", onchange);
    console.log("first", token);
  };

  return (
    <>
      <ReCAPTCHA
        className="w-full md:m-auto md:w-max"
        sitekey={constants.ReCaptcha}
        onChange={onChange}
        ref={captchaRef}
      />
      {isError?.captcha && (
        <p className="text-red-600 text-xs mt-1">{isError.captcha}</p>
      )}
    </>
  );
};

export default Captcha;
