import React from "react";
import { Button } from "primereact/button";

export default function ButtonComponent({
  onClick,
  label,
  loading,
  onChange,
  style,
  icon,
  iconPos,
  className,
  ...props
}) {
  return (
    <Button
      label={label}
      style={style}
      className={`main-button-component text-sm font-medium  border-0 ${className}`}
      onClick={onClick}
      loading={loading}
      onChange={onChange}
      {...props}
    />
  );
}

export function ButtonOutlined({
  onClick,
  label,
  link,
  className,
  loading,
  ...props
}) {
  return (
    <Button
      label={label}
      className={`outline-button text-main-color  font-medium text-sm p-3 border-white w-full ${className}`}
      onClick={onClick}
      loading={loading}
      link={link}
      {...props}
    />
  );
}
