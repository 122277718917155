import AppDevlopmentIcon from "../../assets/icons/app devlopment.png";
import CloudIcon from "../../assets/icons/cloud.png";
import DigitalMarketingIcon from "../../assets/icons/digital marketing.png";
import UIUXIcon from "../../assets/icons/ux.png";
import MobileIcon from "../../assets/icons/mobile application.png";
import BlockchainIcon from "../../assets/icons/blockchain.png";
import MvpIcon from "../../assets/icons/mvp.png";
import DigitalIcon from "../../assets/icons/digital-transformation.png";
import ProductIcon from "../../assets/icons/product-management.png";
import ToolsIcon from "../../assets/icons/tool.png";
import DevlopmentTeamIcon from "../../assets/icons/development (1).png";
import StoreIcon from "../../assets/icons/online-store 1.png";
import SwiftIcon from "../../assets/icons/swift 1.png";
import KotlinIcon from "../../assets/icons/kotlin.png";
import FlutterIcon from "../../assets/icons/flutter-logo.png";
import ReactNativeIcon from "../../assets/icons/react native.png";
import WebAppIcon from "../../assets/icons/progressive web aap.png";
import DjangoIcon from "../../assets/icons/django.png";
import PythonIcon from "../../assets/icons/python 1.png";
import BlockChainIcon2 from "../../assets/icons/blockchain 2.png";
import NodeJsIcon from "../../assets/icons/mdi_nodejs.png";
import AngularIcon from "../../assets/icons/mdi_angular.png";
import ReactJsIcon from "../../assets/icons/icons-reactjs.png";
import LaravelIcon from "../../assets/icons/fa-brands_laravel.png";
import CakePHPIcon from "../../assets/icons/icons-php.png";
import CodeigniterIcon from "../../assets/icons/codeigniter.png";
import ShopifyIcon from "../../assets/icons/ic_baseline-shopify.png";
import WooCommerceIcon from "../../assets/icons/devicon_woocommerce.png";
import WordpressIcon from "../../assets/icons/ic_outline-wordpress.png";
import WixIcon from "../../assets/icons/bxl_wix.png";
import Webflow from "../../assets/icons/skill-icons_webflow.png";
import DineSyncIcon from "../../assets/icons/DineSync.png";
import TaskSyncIcon from "../../assets/icons/TaskSync.png";
import HaircutIcon from "../../assets/icons/haircut 1.png";
import GymBundleIcon from "../../assets/icons/GymSync.png";

const ContentData = () => {
  const ServiceContent = [
    {
      img: UIUXIcon,
      servicename: "UI/UX",
    },
    {
      img: AppDevlopmentIcon,
      servicename: "Web Application Development",
    },
    {
      img: MobileIcon,
      servicename: "Mobile Application Development",
    },
    {
      img: CloudIcon,
      servicename: "Saas Application Development",
    },
    {
      img: BlockchainIcon,
      servicename: "Blockchain Development",
    },
    {
      img: MvpIcon,
      servicename: "MVP Development",
    },
    {
      img: DigitalIcon,
      servicename: "Digital Transformation",
    },
    {
      img: ProductIcon,
      servicename: "Product Development",
    },
    {
      img: DigitalMarketingIcon,
      servicename: "Digital Marketing",
    },
    {
      img: ToolsIcon,
      servicename: "Application Maintenance",
    },
    {
      img: DevlopmentTeamIcon,
      servicename: "Dedicated Development Teams",
    },
    {
      img: StoreIcon,
      servicename: "E-Commerce  Application  Development",
    },
  ];

  const ExpertiesMobile = [
    {
      img: SwiftIcon,
      servicename: "Swift",
    },
    {
      img: KotlinIcon,
      servicename: "Kotlin",
    },
    {
      img: FlutterIcon,
      servicename: "Flutter",
    },
    {
      img: ReactNativeIcon,
      servicename: "React Native",
    },
    {
      img: WebAppIcon,
      servicename: "Progressive Web App",
    },
  ];

  const ExpertiesWeb = [
    {
      img: DjangoIcon,
      servicename: "Django ",
    },
    {
      img: PythonIcon,
      servicename: "Python ",
    },
    {
      img: BlockChainIcon2,
      servicename: "Blockchain",
    },
    {
      img: NodeJsIcon,
      servicename: "Node Js ",
    },

    {
      img: AngularIcon,
      servicename: "Angular is Blockchain  ",
    },
    {
      img: ReactJsIcon,
      servicename: "React Js ",
    },
    {
      img: LaravelIcon,
      servicename: "Laravel ",
    },
    {
      img: CakePHPIcon,
      servicename: "Cake PHP ",
    },
    {
      img: CodeigniterIcon,
      servicename: "Codeigniter ",
    },
  ];

  const Ecommerce = [
    {
      img: ShopifyIcon,
      servicename: "Shopify ",
    },
    {
      img: WooCommerceIcon,
      servicename: "WooCommerce ",
    },
  ];

  const CMS = [
    {
      img: WordpressIcon,
      servicename: "WordPress",
    },
    {
      img: WixIcon,
      servicename: "WIX",
    },
    {
      img: Webflow,
      servicename: "Webflow",
    },
  ];

  const WhiteLabelSolution = [
    {
      img: DineSyncIcon,
      servicename: "DineSync ",
    },
    {
      img: TaskSyncIcon,
      servicename: "TaskSync ",
    },
    {
      img: HaircutIcon,
      servicename: "StyleSync ",
    },
    {
      img: GymBundleIcon,
      servicename: "GymSync",
    },
  ];

  return {
    ServiceContent,
    ExpertiesMobile,
    ExpertiesWeb,
    Ecommerce,
    CMS,
    WhiteLabelSolution,
  };
};
export default ContentData;
